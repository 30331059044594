import { PREFIX_API } from 'constants/index';
import Instance from 'app.v2/config/instance';
import {
  GetQuorumRecordRequestParamDTO,
  GetQuorumRecordResponseDTO,
  ICreateQuorumRecord,
  IQuorumRecordDetail,
} from 'interfaces/QuorumRecord';
export function getQuorumRecordRequest(
  params?: GetQuorumRecordRequestParamDTO,
): Promise<GetQuorumRecordResponseDTO> {
  return Instance.get(`/${PREFIX_API.QUORUM_RECORD}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailQuorumRecord(
  QuorumRecordId: string,
): Promise<IQuorumRecordDetail> {
  return Instance.get(`/${PREFIX_API.QUORUM_RECORD}/${ QuorumRecordId }`);
}
export function createQuorumRecordRequest(
  QuorumRecord: ICreateQuorumRecord,
): Promise<GetQuorumRecordResponseDTO> {
  return Instance.post(`/${PREFIX_API.QUORUM_RECORD}`, QuorumRecord);
}
export function updateQuorumRecordRequest(
  QuorumRecord: ICreateQuorumRecord,
): Promise<GetQuorumRecordResponseDTO> {
  const payload: any = { ...QuorumRecord };
  const id = QuorumRecord.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.QUORUM_RECORD}/${id}`, payload);
}
export function getQuorumRecordRequestPaginationConfig(): Promise<GetQuorumRecordResponseDTO> {
  return Instance.get(`/${PREFIX_API.QUORUM_RECORD}/pagination-config`);
}
