import { SortOrder } from 'antd/lib/table/interface';

export const PER_PAGE = 10;
export const NOT_LIMITED = 100;

export const PREFIX_API = {
  CORE: 'core',
  AUTH: 'auth',
  AUDIT: 'audit',
  TRANSACTION: 'transaction',
  QUORUM: 'advance-quorum',
  WALLET_INFO: 'core/v2/rep/walletInfo',
  CURRENCY: 'core/v2/rep/currency',
  CURRENCYCONVERT: 'core/v2/rep/currencyConvert',
  SNAPSHOT_TOKEN_PRICE: 'core/v2/rep/snapshotTokenPrice',
  WC_WEB3CONNECT: 'wallet-connect/v2/rep/wcWeb3Connect',
  // TODO: define wallet api url
  rakkarConnect: '/wallet-connect',
  WCADAPTER: 'wallet-connect/v2/rep/wcAdapter',
  WCAPPADAPTER: 'wallet-connect/v2/rep/wcAppAdapter',
  WCAPPENTITY: 'wallet-connect/v2/rep/wcAppEntity',
  WCAPPLICATION: 'wallet-connect/v2/rep/wcApplication',
  WCREQUESTWEB3CONNECT: 'wallet-connect/v2/rep/wcRequestWeb3Connect',
  VAULTWC: 'wallet-connect/v2/rep/',
  CUS_SUBSCRIBE: 'wallet-connect/v2/rep/',
  CFG_SOURCEOFFUND: 'core/v2/rep/cfg_sourceOfFund',
  CFG_TYPEOFCOMPANY: 'core/v2/rep/cfg_typeOfCompany',
  CFG_COUNTRY: 'core/v2/rep/cfg_country',
  BATCHELVENTXNRAKFB: 'core/v2/rep/BatchElvenTxnRAKFB',
  BATCHELVENTXNRAKONCHAIN: 'core/v2/rep/BatchElvenTxnRAKONCHAIN',
  REPORTTYPE: 'reports/v2/rep/reportType',
  EXPORTTYPE: 'reports/v2/rep/exportType',
  TRAVELRULEVASP: 'core/v2/rep/TravelRule/VASP',
  TRAVELRULEJURISDICTIONS: 'core/v2/rep/TravelRule/Jurisdictions',
  NOTABENE_APP_BASE_URL:
    process.env.NOTABENE_APP_BASE_URL || 'https://app.notabene.id',
  ACCOUNT_LIMIT_CFG: 'transaction/v2/rep/account-limits/cfg',
  ACCOUNT_LIMIT_POLICY: 'transaction/v2/rep/account-limits/policy',
  ACCOUNT_LIMIT_TEMPLATE: 'transaction/v2/rep/account-limits/template',
  QUORUM_RECORD: 'advance-quorum/v2/rep/quorumRecord',
};

export const SEARCH_DEBONCED = 500;

export const PRODUCT_TYPE = {
  HOT_WALLET: { text: 'Warm wallet', title: 'Hot' },
  COLD_WALLET: { text: 'Cold wallet', title: 'Cold' },
  HOT_AND_COLD: { text: 'Hot wallet and Cold wallet' },
};

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
};

export const STATUS_REQUEST = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};

export const FORMAT_DATE = {
  DEFAULT: 'DD/MM/YYYY',
  DATE_DMY_HM: 'DD/MM/YYYY HH:mm',
  DATE_DMY_hM: 'DD/MM/YYYY hh:mm A',
  DATE_DMY_hM_Z: 'DD/MM/YYYY hh:mm A Z',
  DATE_YMD: 'YYYY-MM-DD',
  DATE_DMY: 'DD-MM-YYYY',
  DATE_MMMMYYYY: 'MMMM YYYY',
  MONTH_YEAR: 'MM/YYYY',
  MM_YYYY: 'MM/YYYY',
};

export const REGEX_ALLOW_AZ_09_SPACE = /^[a-zA-Z0-9 ]+$/;

export const REGEX_ALLOW_AZ_09 = /^[a-zA-Z0-9]+$/;

// eslint-disable-next-line no-useless-escape
export const FIND_SPECIAL_CHAR = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const KEY_CODE = {
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  ENTER: 13,
  DELETE: 8,
  TAB: 9,
  Z: 13,
  X: 88,
  A: 65,
  Y: 89,
};

export const sortOrder: SortOrder[] = ['ascend', 'descend', 'ascend'];

export const commonAttributesColumnTable = {
  sortDirections: sortOrder,
  showSorterTooltip: false,
  sorter: true,
};

export const PathList = {
  customer: '/customer-management',
  request: '/pending-request',
  transaction: '/transaction',
  createCustomer: '/create-customer',
  billing: '/billing',
  auditLog: '/audit-log',
  accessControl: '/access-control',
  walletInfo: '/wallet-info',
  currency: '/currency',
  currencyConvert: '/currencyConvert',
  snapshotTokenPrice: '/snapshotTokenPrice',
  wcWeb3Connect: '/wcWeb3Connect',
  wcAdapter: '/wcAdapter',
  wcAppAdapter: '/wcAppAdapter',
  wcAppEntity: '/wcAppEntity',
  wcApplication: '/wcApplication',
  wcRequestWeb3Connect: '/wcRequestWeb3Connect',
  vaultWc: '/vaultWc',
  cusSubscribe: '/cusSubscribe',
  cfg_sourceOfFund: '/cfg_sourceOfFund',
  cfg_typeOfCompany: '/cfg_typeOfCompany',
  cfg_country: '/cfg_country',
  role: '/role',
  permission: '/permission',
  permissionDetail: '/permissionDetail',
  permissionRoles: '/permissionRoles',
  rakkarConnect: '/wallet-connect',
  BatchElvenTxnRAKFB: '/BatchElvenTxnRAKFB',
  BatchElvenTxnRAKOnChain: '/BatchElvenTxnRAKOnChain',
  ReportType: '/ReportType',
  ExportType: '/ExportType',
  TravelRuleVASP: '/TravelRuleVASP',
  TravelRuleJurisdictions: '/TravelRuleJurisdictions',
  AccountLimitCfg: '/account-limits/cfg',
  AccountLimitPolicy: '/account-limits/policy',
  AccountLimitTem: '/account-limits/template',
  QuorumRecord: '/QuorumRecord',
};

export const FILE_TYPE = {
  PDF: 'application/pdf',
  ZIP: 'application/zip',
  TEXT_CSV: 'text/csv',
} as const;

export const timeZone = -(new Date().getTimezoneOffset() / 60);

export const dotSize = {
  medium: 8,
  large: 12,
};

export enum configMetaData {
  CURRENCY = 'Currency',
  CURRENCY_RATE = 'Currency Rate',
  WALLET_INFO = 'Wallet Info',
}

export const REGEXES = {
  url: new RegExp(
    '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?',
  ),
} as const;
