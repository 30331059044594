import RButton from 'app/components/Button';
import BasicModal from 'app/components/common/BasicModal';
import NoResult from 'app/components/common/NoResult';
import RakkarHeaderFilter from 'app/components/common/RakkarHeaderFilter';
import { SvgIcon } from 'app/components/common/SvgIcon';
import ToastMessage from 'app/components/common/ToastMessage';
import FilterTransaction from 'app/components/Transaction/FilterTransaction';
import ListTransaction from 'app/components/Transaction/ListTransaction';
import clsx from 'clsx';
import { PAGE } from 'enum/common';
import {
  StatusUserApprovalLogs,
  TransactionStatusResponse,
} from 'enum/transaction';
import { Params, RequestStatus } from 'interfaces/request';
import { TransactionParamsRequest } from 'interfaces/transactions';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { exportTransactionListRequest } from 'services/transactions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectIsReviewRequiredOnly,
  selectparamsTransactionList,
  selectTransactionList,
  transactionsActions,
} from 'store/slices/transactions';
import { downloadFile, handleShowMsgErr } from 'utils/common';
import { mapRequest } from 'utils/helpers';
import styles from './index.module.scss';
import ModalReSendFBTransactionIds from 'app/components/Transaction/ModalResendFBTransactionIds';

type Props = {};

const paramNamesFilter = [
  'priceFrom',
  'priceTo',
  'transactionType',
  'dateFrom',
  'dateTo',
  'assetId',
  'status',
  'txnDateFrom',
  'txnDateTo',
];

const Transaction = (props: Props) => {
  const dispatch = useAppDispatch();

  const paramsTransaction = useAppSelector(selectparamsTransactionList);
  const transactionList = useAppSelector(selectTransactionList);
  const isReviewRequiredOnly = useAppSelector(selectIsReviewRequiredOnly);

  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [isHasFilter, setIsHasFilter] = useState(false);

  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);

  const total = transactionList?.data?.totalPendingReview || 0;

  const setValue = (key: keyof TransactionParamsRequest) => {
    return (
      value: TransactionParamsRequest[keyof TransactionParamsRequest],
    ) => {
      dispatch(
        transactionsActions.setTransactionParam({
          key,
          value,
        }),
      );
    };
  };

  const setKeyword = setValue(Params.KeyWord);
  const setOffset = setValue(Params.Offset);
  const setStatus = setValue(Params.Status);

  // TODO: Refresh screen
  const onRefresh = () => {
    setOffset(0);
  };

  // TODO: Keyword search
  const onInputChange = (keyword: string) => {
    dispatch(transactionsActions.resetTransactionList());

    setOffset(0);
    setKeyword(keyword);
  };

  const onFinishFilter = (values: any) => {
    setIsOpenPopover(false);

    const newParams = {
      sort: paramsTransaction?.sort,
      sortBy: paramsTransaction?.sortBy,
      ...values,
      ...(isReviewRequiredOnly
        ? { status: [StatusUserApprovalLogs.PENDING_REVIEW] }
        : {}),
      offset: 0,
      page: 1,
      limit: 10,
    };

    dispatch(
      transactionsActions.setTransactionFilterParams(mapRequest(newParams)),
    );
  };

  const hasSearchOrFilter = useMemo(() => {
    return !!isHasFilter || !!paramsTransaction.keyword;
  }, [isHasFilter, paramsTransaction.keyword]);

  const onDownloadClick = () => setOpenDownloadModal(true);

  const handleConfirmDownload = useCallback(async () => {
    setOpenDownloadModal(false);
    toast(<ToastMessage type="info" message="Transactions being downloaded" />);

    const newParams = {
      ...paramsTransaction,
      offset: 0,
    };

    try {
      const { data } = await exportTransactionListRequest(newParams);
      const filename = `Transaction list_${moment().format('DD.MM.YYYY')}.csv`;
      downloadFile(data, filename);
    } catch (error: any) {
      handleShowMsgErr(error);
    }
  }, [paramsTransaction]);

  useEffect(() => {
    const paramsFilter: any = {};
    paramNamesFilter.forEach(element => {
      paramsFilter[element] = paramsTransaction[element];
    });
    if (paramsFilter?.status && isReviewRequiredOnly) {
      delete paramsFilter.status;
    }

    const isEmpty = Object.values(paramsFilter).some(e => {
      if (
        isReviewRequiredOnly &&
        e === TransactionStatusResponse.PENDING_REVIEW
      ) {
        return false;
      }
      return !!e;
    });
    setIsHasFilter(isEmpty);
  }, [paramsTransaction]);

  useEffect(() => {
    if (
      !transactionList?.data?.transactions?.length &&
      transactionList?.status === RequestStatus.Success
    ) {
      setShowEmptyScreen(true);
    } else {
      setShowEmptyScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionList]);

  const handleFilterReviewRequired = e => {
    if (e.target.checked) {
      setOffset(0);
      setStatus([TransactionStatusResponse.PENDING_REVIEW] as any);
      dispatch(transactionsActions.setIsReviewRequiredOnly(true));
    } else {
      setOffset(0);
      setStatus(undefined);
      dispatch(transactionsActions.setIsReviewRequiredOnly(false));
    }
  };

  return (
    <>
      <Helmet>
        <title>Transaction monitoring</title>
        <meta
          name="description"
          content="A Boilerplate application Transaction monitoring"
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.heading}>
          <SvgIcon name="Rebalance" width={28} height={28} />
          <div className={styles.title}>Transaction monitoring</div>
          <div className={styles.total}>
            <div
              className={clsx(styles.total__number, {
                [styles.total__empty]: total === 0,
              })}
              data-testid="total-request"
            >
              {`${total > 99 ? '99+' : total} pending`}
            </div>
          </div>
          <div className={styles.right}>
            <ModalReSendFBTransactionIds />
          </div>
        </div>
        {showEmptyScreen && !hasSearchOrFilter ? (
          <NoResult
            title="No transactions"
            description={
              <div style={{ marginTop: 8 }}>
                Customer has not received or initiated any <br /> transactions
                yet.
              </div>
            }
          />
        ) : (
          <>
            <div className={styles.heading_filter}>
              <RakkarHeaderFilter
                page={PAGE.TRANSACTION}
                keyword={paramsTransaction.keyword}
                onInputSearch={onInputChange}
                onRefresh={onRefresh}
                onDownload={onDownloadClick}
                isOpenPopover={isOpenPopover}
                setIsOpenPopover={setIsOpenPopover}
                isHasFilter={isHasFilter}
                hasScrollFilter={window.innerHeight <= 720}
                isHasReviewRequired={true}
                handleFilterReviewRequired={handleFilterReviewRequired}
                filterContent={
                  <FilterTransaction
                    isOpen={isOpenPopover}
                    onFinishFilter={onFinishFilter}
                  />
                }
              />
            </div>
            <BasicModal
              destroyOnClose={true}
              closable={false}
              visible={openDownloadModal}
              maskClosable={true}
              width={408}
              centered={true}
              title="Exporting transactions?"
              children={
                <div className={styles.desc}>
                  Only allow to download up to 2,000 transactions
                </div>
              }
              className={styles['download-confirm']}
              onCancel={() => setOpenDownloadModal(false)}
              footer={[
                <RButton
                  data-testid="cancel-button"
                  buttonType="cancel"
                  content="Cancel"
                  onClick={() => setOpenDownloadModal(false)}
                  width={79}
                  height={36}
                  style={{ marginRight: 4 }}
                  key="cancel-button"
                />,
                <RButton
                  key="download-button"
                  data-testid="download-button"
                  htmlType="submit"
                  buttonType="submit"
                  content="Download"
                  onClick={handleConfirmDownload}
                  width={99}
                  height={36}
                />,
              ]}
            />
            <div className={styles.table}>
              <ListTransaction
                hasSearchOrFilter={hasSearchOrFilter}
                showEmptyScreen={showEmptyScreen}
                isHasFilter={isHasFilter}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Transaction;
