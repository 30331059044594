import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getAccountLimitPolicyRequest,
  getDetailAccountLimitPolicy,
  createAccountLimitPolicyRequest,
  updateAccountLimitPolicyRequest,
  getAccountLimitPolicyRequestPaginationConfig,
} from 'services/AccountLimitPolicy.service';
import { AccountLimitPolicyActions } from 'store/slices/AccountLimitPolicy/AccountLimitPolicy.slice';
import { handleShowMsgErr } from 'utils/common';
import { GetAccountLimitPolicyRequestParamDTO, ICreateAccountLimitPolicy } from 'interfaces/AccountLimitPolicy';
const handleGetAccountLimitPolicyRequest = function* ({
  payload,
}: PayloadAction<GetAccountLimitPolicyRequestParamDTO>) {
  try {
    const res = yield call(getAccountLimitPolicyRequest, payload);
    const params = yield select(state => state.AccountLimitPolicy.params) as any;
    const listAccountLimitPolicy = yield select(
      state => state.AccountLimitPolicy?.AccountLimitPolicy?.data?.listAccountLimitPolicy,
    ) as any;
    if (res) {
      yield put(
        AccountLimitPolicyActions.setCanLoadMore(params.offset + params.limit < res.total),
      );
      if (params.offset > 0) {
        const newList: any = [...(listAccountLimitPolicy || []), ...res.list];
        yield put(AccountLimitPolicyActions.appendAccountLimitPolicy(newList));
      } else {
        yield put(
          AccountLimitPolicyActions.getAccountLimitPolicySuccess({
            listAccountLimitPolicy: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitPolicyActions.getAccountLimitPolicyFailed());
  }
};
const handleGetDetailAccountLimitPolicyRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailAccountLimitPolicy, payload);
    if (res) {
      yield put(AccountLimitPolicyActions.getDetailAccountLimitPolicySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitPolicyActions.getDetailAccountLimitPolicyFailed());
  }
};
const handleCreateAccountLimitPolicyRequest = function* ({
  payload,
}: PayloadAction<ICreateAccountLimitPolicy>) {
  try {
    const res = yield call(createAccountLimitPolicyRequest, payload);
    if (res) {
      yield put(AccountLimitPolicyActions.createAccountLimitPolicySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitPolicyActions.createAccountLimitPolicyFailed());
  }
};
const handleUpdateAccountLimitPolicyRequest = function* ({
  payload,
}: PayloadAction<ICreateAccountLimitPolicy>) {
  try {
    const res = yield call(updateAccountLimitPolicyRequest, payload);
    if (res) {
      yield put(AccountLimitPolicyActions.updateAccountLimitPolicySuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitPolicyActions.updateAccountLimitPolicyFailed());
  }
};
const handleGetAccountLimitPolicyPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetAccountLimitPolicyRequestParamDTO>) {
  try {
    const res = yield call(getAccountLimitPolicyRequestPaginationConfig);
    if (res) {
      yield put(AccountLimitPolicyActions.getAccountLimitPolicyPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitPolicyActions.getAccountLimitPolicyPaginationConfigFailed());
  }
};
const AccountLimitPolicySaga = function* () {
  yield takeLatest(AccountLimitPolicyActions.getAccountLimitPolicyRequest.type, handleGetAccountLimitPolicyRequest);
  yield takeLatest(AccountLimitPolicyActions.createAccountLimitPolicyRequest.type, handleCreateAccountLimitPolicyRequest);
  yield takeLatest(AccountLimitPolicyActions.updateAccountLimitPolicyRequest.type, handleUpdateAccountLimitPolicyRequest);
  yield takeLatest(
    AccountLimitPolicyActions.getAccountLimitPolicyPaginationConfigRequest.type,
    handleGetAccountLimitPolicyPaginationConfigRequest,
  );
  yield takeLatest(
    AccountLimitPolicyActions.getDetailAccountLimitPolicyRequest.type,
    handleGetDetailAccountLimitPolicyRequest,
  );
};
export default AccountLimitPolicySaga;
