import { PREFIX_API } from 'constants/index';
import Instance from 'app.v2/config/instance';
import {
  GetAccountLimitPolicyRequestParamDTO,
  GetAccountLimitPolicyResponseDTO,
  ICreateAccountLimitPolicy,
  IAccountLimitPolicyDetail,
} from 'interfaces/AccountLimitPolicy';
export function getAccountLimitPolicyRequest(
  params?: GetAccountLimitPolicyRequestParamDTO,
): Promise<GetAccountLimitPolicyResponseDTO> {
  return Instance.get(`/${PREFIX_API.ACCOUNT_LIMIT_POLICY}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailAccountLimitPolicy(
  AccountLimitPolicyId: string,
): Promise<IAccountLimitPolicyDetail> {
  return Instance.get(`/${PREFIX_API.ACCOUNT_LIMIT_POLICY}/${ AccountLimitPolicyId }`);
}
export function createAccountLimitPolicyRequest(
  AccountLimitPolicy: ICreateAccountLimitPolicy,
): Promise<GetAccountLimitPolicyResponseDTO> {
  return Instance.post(`/${PREFIX_API.ACCOUNT_LIMIT_POLICY}`, AccountLimitPolicy);
}
export function updateAccountLimitPolicyRequest(
  AccountLimitPolicy: ICreateAccountLimitPolicy,
): Promise<GetAccountLimitPolicyResponseDTO> {
  const payload: any = { ...AccountLimitPolicy };
  const id = AccountLimitPolicy.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.ACCOUNT_LIMIT_POLICY}/${id}`, payload);
}
export function getAccountLimitPolicyRequestPaginationConfig(): Promise<GetAccountLimitPolicyResponseDTO> {
  return Instance.get(`/${PREFIX_API.ACCOUNT_LIMIT_POLICY}/pagination-config`);
}
