import clsx from 'clsx';
import moment from 'moment';
import styles from './index.module.scss';
import { Col, Divider, Row } from 'antd';
import { FORMAT_DATE } from 'constants/index';

type Props = {
  drawerWidth?: number;
  transactionData?: any;
  folder?: any;
};

interface ITextInfor {
  label: any;
  text: any;
  span?: number;
  dataTestId?: string;
  isEmpty?: boolean;
  isLast?: boolean;
}

const AssetInformation = ({
  drawerWidth = 665,
  transactionData,
  folder,
}: Props) => {
  const TextInfor = ({
    span = 12,
    label,
    text,
    dataTestId,
    isEmpty = false,
    isLast = false,
  }: ITextInfor) => {
    return (
      <Col span={span} style={{ paddingRight: 15 }}>
        <div
          className={clsx(styles.content__item, {
            [styles.content__last_item]: isLast,
          })}
        >
          <p className={styles.content__label}>{label}</p>
          <p className={styles.content__text} data-testid={dataTestId}>
            {isEmpty ? '-' : text}
          </p>
        </div>
      </Col>
    );
  };

  return (
    <div className={styles.content}>
      <Row className={styles.caption}>Asset information</Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Asset"
          dataTestId="asset"
          text={
            <Row style={{ gap: 8 }} align="middle">
              <div className={styles.icon}>
                <img src={transactionData?.icon} alt="" />
              </div>
              <span className={clsx(styles.name, styles.bold__name)}>
                {transactionData?.symbol}
              </span>
              <span className={clsx(styles.name, styles.sub__name)}>
                {transactionData?.name}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Network"
          dataTestId="network"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {transactionData?.network}
              </span>
            </Row>
          }
        />
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Address"
          dataTestId="address"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {folder?.address?.address}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Memo"
          dataTestId="memo"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {folder?.address?.tag || '-'}
              </span>
            </Row>
          }
        />
      </Row>
      <Row>
        <TextInfor
          label="Whitelisted date"
          dataTestId="whitelisted-date"
          text={
            <Row style={{ gap: 8 }} align="middle">
              <span className={clsx(styles.name, styles.normal__name)}>
                {moment(folder?.address?.activedAt || null).format(
                  FORMAT_DATE.DATE_DMY_hM,
                )}
              </span>
            </Row>
          }
        />
      </Row>
      <Divider orientationMargin="0" style={{ margin: '15px 0 0px' }} />
    </div>
  );
};

export default AssetInformation;
