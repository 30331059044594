import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getAccountLimitCfgRequest,
  getDetailAccountLimitCfg,
  createAccountLimitCfgRequest,
  updateAccountLimitCfgRequest,
  getAccountLimitCfgRequestPaginationConfig,
} from 'services/AccountLimitCfg.service';
import { AccountLimitCfgActions } from 'store/slices/AccountLimitCfg/AccountLimitCfg.slice';
import { handleShowMsgErr } from 'utils/common';
import { GetAccountLimitCfgRequestParamDTO, ICreateAccountLimitCfg } from 'interfaces/AccountLimitCfg';
const handleGetAccountLimitCfgRequest = function* ({
  payload,
}: PayloadAction<GetAccountLimitCfgRequestParamDTO>) {
  try {
    const res = yield call(getAccountLimitCfgRequest, payload);
    const params = yield select(state => state.AccountLimitCfg.params) as any;
    const listAccountLimitCfg = yield select(
      state => state.AccountLimitCfg?.AccountLimitCfg?.data?.listAccountLimitCfg,
    ) as any;
    if (res) {
      yield put(
        AccountLimitCfgActions.setCanLoadMore(params.offset + params.limit < res.total),
      );
      if (params.offset > 0) {
        const newList: any = [...(listAccountLimitCfg || []), ...res.list];
        yield put(AccountLimitCfgActions.appendAccountLimitCfg(newList));
      } else {
        yield put(
          AccountLimitCfgActions.getAccountLimitCfgSuccess({
            listAccountLimitCfg: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitCfgActions.getAccountLimitCfgFailed());
  }
};
const handleGetDetailAccountLimitCfgRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailAccountLimitCfg, payload);
    if (res) {
      yield put(AccountLimitCfgActions.getDetailAccountLimitCfgSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitCfgActions.getDetailAccountLimitCfgFailed());
  }
};
const handleCreateAccountLimitCfgRequest = function* ({
  payload,
}: PayloadAction<ICreateAccountLimitCfg>) {
  try {
    const res = yield call(createAccountLimitCfgRequest, payload);
    if (res) {
      yield put(AccountLimitCfgActions.createAccountLimitCfgSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitCfgActions.createAccountLimitCfgFailed());
  }
};
const handleUpdateAccountLimitCfgRequest = function* ({
  payload,
}: PayloadAction<ICreateAccountLimitCfg>) {
  try {
    const res = yield call(updateAccountLimitCfgRequest, payload);
    if (res) {
      yield put(AccountLimitCfgActions.updateAccountLimitCfgSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitCfgActions.updateAccountLimitCfgFailed());
  }
};
const handleGetAccountLimitCfgPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetAccountLimitCfgRequestParamDTO>) {
  try {
    const res = yield call(getAccountLimitCfgRequestPaginationConfig);
    if (res) {
      yield put(AccountLimitCfgActions.getAccountLimitCfgPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitCfgActions.getAccountLimitCfgPaginationConfigFailed());
  }
};
const AccountLimitCfgSaga = function* () {
  yield takeLatest(AccountLimitCfgActions.getAccountLimitCfgRequest.type, handleGetAccountLimitCfgRequest);
  yield takeLatest(AccountLimitCfgActions.createAccountLimitCfgRequest.type, handleCreateAccountLimitCfgRequest);
  yield takeLatest(AccountLimitCfgActions.updateAccountLimitCfgRequest.type, handleUpdateAccountLimitCfgRequest);
  yield takeLatest(
    AccountLimitCfgActions.getAccountLimitCfgPaginationConfigRequest.type,
    handleGetAccountLimitCfgPaginationConfigRequest,
  );
  yield takeLatest(
    AccountLimitCfgActions.getDetailAccountLimitCfgRequest.type,
    handleGetDetailAccountLimitCfgRequest,
  );
};
export default AccountLimitCfgSaga;
