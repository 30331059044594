import { PREFIX_API } from 'constants/index';
import Instance from 'app.v2/config/instance';
import {
  GetAccountLimitCfgRequestParamDTO,
  GetAccountLimitCfgResponseDTO,
  ICreateAccountLimitCfg,
  IAccountLimitCfgDetail,
} from 'interfaces/AccountLimitCfg';
export function getAccountLimitCfgRequest(
  params?: GetAccountLimitCfgRequestParamDTO,
): Promise<GetAccountLimitCfgResponseDTO> {
  return Instance.get(`/${PREFIX_API.ACCOUNT_LIMIT_CFG}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailAccountLimitCfg(
  AccountLimitCfgId: string,
): Promise<IAccountLimitCfgDetail> {
  return Instance.get(`/${PREFIX_API.ACCOUNT_LIMIT_CFG}/${ AccountLimitCfgId }`);
}
export function createAccountLimitCfgRequest(
  AccountLimitCfg: ICreateAccountLimitCfg,
): Promise<GetAccountLimitCfgResponseDTO> {
  return Instance.post(`/${PREFIX_API.ACCOUNT_LIMIT_CFG}`, AccountLimitCfg);
}
export function updateAccountLimitCfgRequest(
  AccountLimitCfg: ICreateAccountLimitCfg,
): Promise<GetAccountLimitCfgResponseDTO> {
  const payload: any = { ...AccountLimitCfg };
  const id = AccountLimitCfg.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.ACCOUNT_LIMIT_CFG}/${id}`, payload);
}
export function getAccountLimitCfgRequestPaginationConfig(): Promise<GetAccountLimitCfgResponseDTO> {
  return Instance.get(`/${PREFIX_API.ACCOUNT_LIMIT_CFG}/pagination-config`);
}
