import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getAccountLimitTemRequest,
  getDetailAccountLimitTem,
  createAccountLimitTemRequest,
  updateAccountLimitTemRequest,
  getAccountLimitTemRequestPaginationConfig,
} from 'services/AccountLimitTem.service';
import { AccountLimitTemActions } from 'store/slices/AccountLimitTem/AccountLimitTem.slice';
import { handleShowMsgErr } from 'utils/common';
import { GetAccountLimitTemRequestParamDTO, ICreateAccountLimitTem } from 'interfaces/AccountLimitTem';
const handleGetAccountLimitTemRequest = function* ({
  payload,
}: PayloadAction<GetAccountLimitTemRequestParamDTO>) {
  try {
    const res = yield call(getAccountLimitTemRequest, payload);
    const params = yield select(state => state.AccountLimitTem.params) as any;
    const listAccountLimitTem = yield select(
      state => state.AccountLimitTem?.AccountLimitTem?.data?.listAccountLimitTem,
    ) as any;
    if (res) {
      yield put(
        AccountLimitTemActions.setCanLoadMore(params.offset + params.limit < res.total),
      );
      if (params.offset > 0) {
        const newList: any = [...(listAccountLimitTem || []), ...res.list];
        yield put(AccountLimitTemActions.appendAccountLimitTem(newList));
      } else {
        yield put(
          AccountLimitTemActions.getAccountLimitTemSuccess({
            listAccountLimitTem: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitTemActions.getAccountLimitTemFailed());
  }
};
const handleGetDetailAccountLimitTemRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailAccountLimitTem, payload);
    if (res) {
      yield put(AccountLimitTemActions.getDetailAccountLimitTemSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitTemActions.getDetailAccountLimitTemFailed());
  }
};
const handleCreateAccountLimitTemRequest = function* ({
  payload,
}: PayloadAction<ICreateAccountLimitTem>) {
  try {
    const res = yield call(createAccountLimitTemRequest, payload);
    if (res) {
      yield put(AccountLimitTemActions.createAccountLimitTemSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitTemActions.createAccountLimitTemFailed());
  }
};
const handleUpdateAccountLimitTemRequest = function* ({
  payload,
}: PayloadAction<ICreateAccountLimitTem>) {
  try {
    const res = yield call(updateAccountLimitTemRequest, payload);
    if (res) {
      yield put(AccountLimitTemActions.updateAccountLimitTemSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitTemActions.updateAccountLimitTemFailed());
  }
};
const handleGetAccountLimitTemPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetAccountLimitTemRequestParamDTO>) {
  try {
    const res = yield call(getAccountLimitTemRequestPaginationConfig);
    if (res) {
      yield put(AccountLimitTemActions.getAccountLimitTemPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(AccountLimitTemActions.getAccountLimitTemPaginationConfigFailed());
  }
};
const AccountLimitTemSaga = function* () {
  yield takeLatest(AccountLimitTemActions.getAccountLimitTemRequest.type, handleGetAccountLimitTemRequest);
  yield takeLatest(AccountLimitTemActions.createAccountLimitTemRequest.type, handleCreateAccountLimitTemRequest);
  yield takeLatest(AccountLimitTemActions.updateAccountLimitTemRequest.type, handleUpdateAccountLimitTemRequest);
  yield takeLatest(
    AccountLimitTemActions.getAccountLimitTemPaginationConfigRequest.type,
    handleGetAccountLimitTemPaginationConfigRequest,
  );
  yield takeLatest(
    AccountLimitTemActions.getDetailAccountLimitTemRequest.type,
    handleGetDetailAccountLimitTemRequest,
  );
};
export default AccountLimitTemSaga;
