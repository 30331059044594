import moment from 'moment';
import { FORMAT_DATE } from 'constants/index';

export const formatDate = (
  date: any,
  formatDate = FORMAT_DATE.DEFAULT,
  isUtc = false,
) => {
  if (isUtc) {
    return moment(date).utc().format(formatDate);
  }

  return moment(date).format(formatDate);
};

export const formatDateRange = (
  startDate: any,
  endDate?: any,
  format = FORMAT_DATE.DEFAULT,
  isUtc = false,
  noEndDateSubstitution = 'Since',
) => {
  if (endDate) {
    return `${formatDate(startDate, format, isUtc)} - ${formatDate(
      endDate,
      format,
      isUtc,
    )}`;
  }

  return `${noEndDateSubstitution} ${formatDate(startDate, format, isUtc)}`;
};

export const formatTimestamp = timestamp => {
  if (timestamp) {
    // Determine if the timestamp is in seconds (10 digits) or milliseconds (13 digits)
    const isInSeconds: boolean = timestamp.toString().length === 10;
    const adjustedTimestamp: number = isInSeconds
      ? timestamp * 1000
      : timestamp;

    // Create a Moment.js object
    const date = moment(adjustedTimestamp);

    // Format the date
    return date.format('DD/MM/YYYY hh:mm A');
  }
};
