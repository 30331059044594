import { PREFIX_API } from 'constants/index';
import Instance from 'app.v2/config/instance';
import {
  GetAccountLimitTemRequestParamDTO,
  GetAccountLimitTemResponseDTO,
  ICreateAccountLimitTem,
  IAccountLimitTemDetail,
} from 'interfaces/AccountLimitTem';
export function getAccountLimitTemRequest(
  params?: GetAccountLimitTemRequestParamDTO,
): Promise<GetAccountLimitTemResponseDTO> {
  console.debug(`💀 ${(new Date()).toISOString()} ~ file: AccountLimitTem.service.ts:12 ~ params:`, params)
  return Instance.get(`/${PREFIX_API.ACCOUNT_LIMIT_TEMPLATE}`, {
    params: {
      ...params,
      where: JSON.stringify(params?.where || {}),
      order: JSON.stringify(params?.order || []),
    },
  });
}
export function getDetailAccountLimitTem(
  AccountLimitTemId: string,
): Promise<IAccountLimitTemDetail> {
  return Instance.get(`/${PREFIX_API.ACCOUNT_LIMIT_TEMPLATE}/${ AccountLimitTemId }`);
}
export function createAccountLimitTemRequest(
  AccountLimitTem: ICreateAccountLimitTem,
): Promise<GetAccountLimitTemResponseDTO> {
  return Instance.post(`/${PREFIX_API.ACCOUNT_LIMIT_TEMPLATE}`, AccountLimitTem);
}
export function updateAccountLimitTemRequest(
  AccountLimitTem: ICreateAccountLimitTem,
): Promise<GetAccountLimitTemResponseDTO> {
  const payload: any = { ...AccountLimitTem };
  const id = AccountLimitTem.id;
  delete payload.id;
  return Instance.put(`/${PREFIX_API.ACCOUNT_LIMIT_TEMPLATE}/${id}`, payload);
}
export function getAccountLimitTemRequestPaginationConfig(): Promise<GetAccountLimitTemResponseDTO> {
  return Instance.get(`/${PREFIX_API.ACCOUNT_LIMIT_TEMPLATE}/pagination-config`);
}
