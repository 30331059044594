import { Col, Row } from 'antd';
import clsx from 'clsx';
import styles from './index.module.scss';
import BeneficiaryInformation from '../BeneficiaryInformation';
import OriginatorInformation from '../OriginatorInformation';
import { FolderAddressMethod, WalletHost } from 'enum/transaction';

type Props = {
  drawerWidth?: number;
  transactionData?: any;
  folder: any;
  vasp: any;
  customer: any;
};

interface ITextInfor {
  label: any;
  text: any;
  span?: number;
  dataTestId?: string;
  isEmpty?: boolean;
  isLast?: boolean;
}

const WalletVerification = ({
  drawerWidth = 665,
  transactionData,
  folder,
  vasp,
  customer,
}: Props) => {
  const TextInfor = ({
    span = 12,
    label,
    text,
    dataTestId,
    isEmpty = false,
    isLast = false,
  }: ITextInfor) => {
    return (
      <Col span={span} style={{ paddingRight: 15 }}>
        <div
          className={clsx(styles.content__item, {
            [styles.content__last_item]: isLast,
          })}
        >
          <p className={styles.content__label}>{label}</p>
          <p className={styles.content__text} data-testid={dataTestId}>
            {isEmpty ? '-' : text}
          </p>
        </div>
      </Col>
    );
  };

  return (
    <div className={styles.content}>
      <Row className={styles.caption}>Wallet verification</Row>
      <Row style={{ marginBottom: 10 }}>
        <TextInfor
          label="Wallet host"
          dataTestId="wallet-host"
          text={
            <Row style={{ gap: 8 }} align="middle">
              <span className={clsx(styles.name, styles.normal__name)}>
                {[WalletHost.REGISTERED_VASP, WalletHost.VASP].includes(
                  folder?.address?.walletHost,
                )
                  ? 'Registered VASP'
                  : folder?.address?.walletHost === WalletHost.SELF_HOSTED
                  ? 'Self-hosted'
                  : 'Other exchanges or wallet platforms'}
              </span>
            </Row>
          }
        />
        <TextInfor
          label="Method"
          dataTestId="method"
          text={
            <Row style={{ gap: 8 }}>
              <span className={clsx(styles.name, styles.normal__name)}>
                {folder?.address?.method === FolderAddressMethod.TRAVEL_RULE
                  ? 'Travel rule'
                  : 'Self-attestation'}
              </span>
            </Row>
          }
        />
      </Row>
      {[WalletHost.REGISTERED_VASP, WalletHost.VASP].includes(
        folder?.address?.walletHost,
      ) &&
        folder?.address?.method === FolderAddressMethod.TRAVEL_RULE && (
          <>
            <Row style={{ marginBottom: 20 }}>
              <OriginatorInformation customer={customer} />
            </Row>
            <Row>
              <BeneficiaryInformation folder={folder} vasp={vasp} />
            </Row>
          </>
        )}
    </div>
  );
};

export default WalletVerification;
