import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortAccountLimitPolicy, GroupByField, CustomerOnScreen } from 'enum/AccountLimitPolicy.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetAccountLimitPolicyRequestParamDTO,
  GetAccountLimitPolicyResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IAccountLimitPolicyDetail,
} from 'interfaces/AccountLimitPolicy';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface AccountLimitPolicyState {
  params: GetAccountLimitPolicyRequestParamDTO;
  AccountLimitPolicy: ResponseData<GetAccountLimitPolicyResponseDTO>;
  AccountLimitPolicyPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  AccountLimitPolicyDetail: ResponseData<IAccountLimitPolicyDetail>;
}
export function getDefaultAccountLimitPolicyRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortAccountLimitPolicy.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: AccountLimitPolicyState = {
  params: getDefaultAccountLimitPolicyRequestParams(),
  AccountLimitPolicy: {
    status: RequestStatus.Idle,
  },
  AccountLimitPolicyPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.ACCOUNT_LIMIT_POLICY,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  AccountLimitPolicyDetail: {
    status: RequestStatus.Idle,
  },
};
const getAccountLimitPolicyRequest: CaseReducer<AccountLimitPolicyState, PayloadAction<any>> = (
  state: AccountLimitPolicyState,
) => {
  state.AccountLimitPolicy.status = RequestStatus.Loading;
};
const getAccountLimitPolicySuccess: CaseReducer<
  AccountLimitPolicyState,
  PayloadAction<GetAccountLimitPolicyResponseDTO>
> = (state: AccountLimitPolicyState, { payload }: PayloadAction<GetAccountLimitPolicyResponseDTO>) => {
  state.AccountLimitPolicy.status = RequestStatus.Success;
  state.AccountLimitPolicy.data = payload;
};
const getAccountLimitPolicyFailed: CaseReducer<AccountLimitPolicyState> = (state: AccountLimitPolicyState) => {
  state.AccountLimitPolicy.status = RequestStatus.Failed;
};
const getDetailAccountLimitPolicyRequest: CaseReducer<AccountLimitPolicyState, PayloadAction<string>> = (
  state: AccountLimitPolicyState,
) => {
  state.AccountLimitPolicyDetail.status = RequestStatus.Loading;
};
const getDetailAccountLimitPolicySuccess: CaseReducer<
  AccountLimitPolicyState,
  PayloadAction<IAccountLimitPolicyDetail>
> = (state: AccountLimitPolicyState, { payload }: PayloadAction<IAccountLimitPolicyDetail>) => {
  state.AccountLimitPolicyDetail.status = RequestStatus.Success;
  state.AccountLimitPolicyDetail.data = payload;
};
const getDetailAccountLimitPolicyFailed: CaseReducer<AccountLimitPolicyState> = (state: AccountLimitPolicyState) => {
  state.AccountLimitPolicyDetail.status = RequestStatus.Failed;
};
const createAccountLimitPolicyRequest: CaseReducer<AccountLimitPolicyState, PayloadAction<IAccountLimitPolicyDetail>> = (
  state: AccountLimitPolicyState,
) => {
  state.AccountLimitPolicyDetail.status = RequestStatus.Loading;
};
const createAccountLimitPolicySuccess: CaseReducer<AccountLimitPolicyState, PayloadAction<IAccountLimitPolicyDetail>> = (
  state: AccountLimitPolicyState,
  { payload }: PayloadAction<IAccountLimitPolicyDetail>,
) => {
  state.AccountLimitPolicyDetail.status = RequestStatus.Success;
  state.AccountLimitPolicyDetail.data = payload;
};
const createAccountLimitPolicyFailed: CaseReducer<AccountLimitPolicyState> = (state: AccountLimitPolicyState) => {
  state.AccountLimitPolicyDetail.status = RequestStatus.Failed;
};
const updateAccountLimitPolicyRequest: CaseReducer<AccountLimitPolicyState, PayloadAction<IAccountLimitPolicyDetail>> = (
  state: AccountLimitPolicyState,
) => {
  state.AccountLimitPolicyDetail.status = RequestStatus.Loading;
};
const updateAccountLimitPolicySuccess: CaseReducer<AccountLimitPolicyState, PayloadAction<IAccountLimitPolicyDetail>> = (
  state: AccountLimitPolicyState,
  { payload }: PayloadAction<IAccountLimitPolicyDetail>,
) => {
  state.AccountLimitPolicyDetail.status = RequestStatus.Success;
  state.AccountLimitPolicyDetail.data = payload;
};
const updateAccountLimitPolicyFailed: CaseReducer<AccountLimitPolicyState> = (state: AccountLimitPolicyState) => {
  state.AccountLimitPolicyDetail.status = RequestStatus.Failed;
};
const appendAccountLimitPolicy: CaseReducer<AccountLimitPolicyState, PayloadAction<any>> = (
  state: AccountLimitPolicyState,
  { payload }: PayloadAction<any>,
) => {
  if (state.AccountLimitPolicy?.data?.listAccountLimitPolicy) {
    state.AccountLimitPolicy.status = RequestStatus.Success;
    state.AccountLimitPolicy.data.listAccountLimitPolicy = [...payload];
  }
};
const setCanLoadMore = (state: AccountLimitPolicyState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetAccountLimitPolicy: CaseReducer<AccountLimitPolicyState> = (state: AccountLimitPolicyState) => {
  state.AccountLimitPolicy = {
    status: RequestStatus.Idle,
  };
};
const resetAccountLimitPolicyParams: CaseReducer<AccountLimitPolicyState> = (state: AccountLimitPolicyState) => {
  state.params = getDefaultAccountLimitPolicyRequestParams();
};
const setAccountLimitPolicyFilterParams = (
  state: AccountLimitPolicyState,
  { payload }: PayloadAction<GetAccountLimitPolicyRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  AccountLimitPolicyState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: AccountLimitPolicyState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<AccountLimitPolicyState, PayloadAction<any>> = (
  state: AccountLimitPolicyState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<AccountLimitPolicyState> = (state: AccountLimitPolicyState) => {
  state.action.status = RequestStatus.Failed;
};
const getAccountLimitPolicyPaginationConfigRequest: CaseReducer<
  AccountLimitPolicyState,
  PayloadAction<any>
> = (state: AccountLimitPolicyState) => {
  state.AccountLimitPolicyPaginationConfig.status = RequestStatus.Loading;
};
const getAccountLimitPolicyPaginationConfigSuccess: CaseReducer<
  AccountLimitPolicyState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: AccountLimitPolicyState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.AccountLimitPolicyPaginationConfig.status = RequestStatus.Success;
  state.AccountLimitPolicyPaginationConfig.data = payload;
};
const getAccountLimitPolicyPaginationConfigFailed: CaseReducer<AccountLimitPolicyState> = (
  state: AccountLimitPolicyState,
) => {
  state.AccountLimitPolicyPaginationConfig.status = RequestStatus.Failed;
};
const AccountLimitPolicySlice = createSlice({
  name: 'AccountLimitPolicy',
  initialState,
  reducers: {
    getAccountLimitPolicyRequest,
    getAccountLimitPolicySuccess,
    getAccountLimitPolicyFailed,
    getDetailAccountLimitPolicyRequest,
    getDetailAccountLimitPolicySuccess,
    getDetailAccountLimitPolicyFailed,
    createAccountLimitPolicyRequest,
    createAccountLimitPolicySuccess,
    createAccountLimitPolicyFailed,
    updateAccountLimitPolicyRequest,
    updateAccountLimitPolicySuccess,
    updateAccountLimitPolicyFailed,
    appendAccountLimitPolicy,
    setCanLoadMore,
    resetAccountLimitPolicy,
    setAccountLimitPolicyParams<K extends keyof GetAccountLimitPolicyRequestParamDTO>(
      state: AccountLimitPolicyState,
      action: PayloadAction<{
        key: K;
        value: GetAccountLimitPolicyRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setAccountLimitPolicyFilterParams,
    resetAccountLimitPolicyParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getAccountLimitPolicyPaginationConfigRequest,
    getAccountLimitPolicyPaginationConfigSuccess,
    getAccountLimitPolicyPaginationConfigFailed,
  },
});
// Actions
export const AccountLimitPolicyActions = AccountLimitPolicySlice.actions;
// Reducer
export const AccountLimitPolicyReducer = AccountLimitPolicySlice.reducer;
// Selectors
export const selectAccountLimitPolicy = (state: AppState) => state.AccountLimitPolicy?.AccountLimitPolicy;
export const selectParamAccountLimitPolicy = (state: AppState) => state.AccountLimitPolicy?.params;
export const selectCanLoadMoreAccountLimitPolicy = (state: AppState) =>
  state.AccountLimitPolicy?.canLoadMore;
export const selectAccountLimitPolicyDetail = (state: AppState) => state.AccountLimitPolicy?.AccountLimitPolicyDetail;
export const selectAccountLimitPolicyPagination = (state: AppState) =>
  state.AccountLimitPolicy?.AccountLimitPolicyPaginationConfig;
