import { USER_ROLES } from 'enum/common';
import { SourceType } from 'enum/transaction';
import { TierTooltip } from '../../TransactionInformation';
import styles from './index.module.scss';

interface ICheckListStatusProps {
  sourceType?: string;
  transactionType?: string;
  transactionStatus?: string;
  tier?: any;
  role?: string;
}

export const ReviewTransactionChecklist = () => {
  return <>Review transaction detail</>;
};

export const ReviewKYTInfomation = (props: ICheckListStatusProps) => {
  return (
    <>
      {props?.sourceType === SourceType.COLD_WALLET && (
        <span className={`${styles.tier} ${styles.mid__tier}`}>
          <TierTooltip title="" content="TIER 2" />
        </span>
      )}
      <span className={`${styles.tier} ${styles.large__tier}`}>
        <TierTooltip title="" content="TIER 3" />
      </span>
      Review KYT Information
    </>
  );
};

export const ReviewKYTTravelRuleInformation = () => {
  return (
    <>Review KYT information and verify or confirm Travel Rule details as applicable</>
  );
};

export const CheckApprovalLog = (props: ICheckListStatusProps) => {
  return <>Verify approval log with quorum requirement</>;
};

export const VerifyCustomer = () => {
  return <>Verify with customer</>;
};

export const VerifyCustomerVDO = () => {
  return (
    <>
      <span className={`${styles.tier} ${styles.large__tier}`}>
        <TierTooltip title="" content="TIER 3" />
      </span>
      Verify customer identity from live VDO capture
      <ul className={styles.list_VDO}>
        <li>Liveness check (Speech prompt)</li>
        <li>Compare live VDO with onboarding VDO and passport/ID</li>
      </ul>
    </>
  );
};

export const SignTransaction = (props: ICheckListStatusProps) => {
  return props.sourceType === SourceType.COLD_WALLET ? (
    <>Sign transaction on Fireblocks Cold wallet app</>
  ) : (
    <>Approve transaction on Fireblocks mobile app</>
  );
};

export const ApprovalTransaction = (props: ICheckListStatusProps) => {
  return (
    <>
      {props.role === USER_ROLES.COMPLIANCE && (
        <>
          {props.sourceType === SourceType.COLD_WALLET && (
            <span className={`${styles.tier} ${styles.mid__tier}`}>
              <TierTooltip title="" content="TIER 2" />
            </span>
          )}
          <span className={`${styles.tier} ${styles.large__tier}`}>
            <TierTooltip title="" content="TIER 3" />
          </span>
        </>
      )}
      Approve transaction on Fireblocks mobile app
    </>
  );
};
