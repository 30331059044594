import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getQuorumRecordRequest,
  getDetailQuorumRecord,
  createQuorumRecordRequest,
  updateQuorumRecordRequest,
  getQuorumRecordRequestPaginationConfig,
} from 'services/QuorumRecord.service';
import { QuorumRecordActions } from 'store/slices/QuorumRecord/QuorumRecord.slice';
import { handleShowMsgErr } from 'utils/common';
import { GetQuorumRecordRequestParamDTO, ICreateQuorumRecord } from 'interfaces/QuorumRecord';
const handleGetQuorumRecordRequest = function* ({
  payload,
}: PayloadAction<GetQuorumRecordRequestParamDTO>) {
  try {
    const res = yield call(getQuorumRecordRequest, payload);
    const params = yield select(state => state.QuorumRecord.params) as any;
    const listQuorumRecord = yield select(
      state => state.QuorumRecord?.QuorumRecord?.data?.listQuorumRecord,
    ) as any;
    if (res) {
      yield put(
        QuorumRecordActions.setCanLoadMore(params.offset + params.limit < res.total),
      );
      if (params.offset > 0) {
        const newList: any = [...(listQuorumRecord || []), ...res.list];
        yield put(QuorumRecordActions.appendQuorumRecord(newList));
      } else {
        yield put(
          QuorumRecordActions.getQuorumRecordSuccess({
            listQuorumRecord: res.list,
            total: res.total,
          }),
        );
      }
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(QuorumRecordActions.getQuorumRecordFailed());
  }
};
const handleGetDetailQuorumRecordRequest = function* ({
  payload,
}: PayloadAction<string>) {
  try {
    const res = yield call(getDetailQuorumRecord, payload);
    if (res) {
      yield put(QuorumRecordActions.getDetailQuorumRecordSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(QuorumRecordActions.getDetailQuorumRecordFailed());
  }
};
const handleCreateQuorumRecordRequest = function* ({
  payload,
}: PayloadAction<ICreateQuorumRecord>) {
  try {
    const res = yield call(createQuorumRecordRequest, payload);
    if (res) {
      yield put(QuorumRecordActions.createQuorumRecordSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(QuorumRecordActions.createQuorumRecordFailed());
  }
};
const handleUpdateQuorumRecordRequest = function* ({
  payload,
}: PayloadAction<ICreateQuorumRecord>) {
  try {
    const res = yield call(updateQuorumRecordRequest, payload);
    if (res) {
      yield put(QuorumRecordActions.updateQuorumRecordSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(QuorumRecordActions.updateQuorumRecordFailed());
  }
};
const handleGetQuorumRecordPaginationConfigRequest = function* ({
  payload,
}: PayloadAction<GetQuorumRecordRequestParamDTO>) {
  try {
    const res = yield call(getQuorumRecordRequestPaginationConfig);
    if (res) {
      yield put(QuorumRecordActions.getQuorumRecordPaginationConfigSuccess(res));
    }
  } catch (error: any) {
    handleShowMsgErr(error);
    yield put(QuorumRecordActions.getQuorumRecordPaginationConfigFailed());
  }
};
const QuorumRecordSaga = function* () {
  yield takeLatest(QuorumRecordActions.getQuorumRecordRequest.type, handleGetQuorumRecordRequest);
  yield takeLatest(QuorumRecordActions.createQuorumRecordRequest.type, handleCreateQuorumRecordRequest);
  yield takeLatest(QuorumRecordActions.updateQuorumRecordRequest.type, handleUpdateQuorumRecordRequest);
  yield takeLatest(
    QuorumRecordActions.getQuorumRecordPaginationConfigRequest.type,
    handleGetQuorumRecordPaginationConfigRequest,
  );
  yield takeLatest(
    QuorumRecordActions.getDetailQuorumRecordRequest.type,
    handleGetDetailQuorumRecordRequest,
  );
};
export default QuorumRecordSaga;
