import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';

import { PER_PAGE, timeZone } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import {
  SortAuditLogList,
  GroupByField,
  CustomerOnScreen,
} from 'enum/auditLogList.enum';

import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetAuditLogListRequestParamDTO,
  GetAuditLogListResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IAuditLogDetail,
} from 'interfaces/auditLogList.interface';

import { AppState } from '../..';
import { defaultDateRange } from 'app/components/AuditLog/AuditLogFilter';
interface AuditLogListState {
  params: GetAuditLogListRequestParamDTO;
  auditLogList: ResponseData<GetAuditLogListResponseDTO>;
  canLoadMore: boolean;

  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;

  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;

  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;

  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;

  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;

  auditLogDetail: ResponseData<IAuditLogDetail>;
}

export function getDefaultAuditLogListRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.SortBy]: SortAuditLogList.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
    dateFrom: defaultDateRange?.[0]?.toISOString(),
    dateTo: defaultDateRange?.[1]?.toISOString(),
    timeZone: (-12 <= timeZone && timeZone <= 14)? timeZone: undefined,
  };
}

export function getDefaultCompanyRequestParams() {}

export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}

export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}

export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    customerIds: '',
  };
}

const initialState: AuditLogListState = {
  params: getDefaultAuditLogListRequestParams(),
  auditLogList: {
    status: RequestStatus.Idle,
  },

  canLoadMore: true,

  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.AUDIT_LOG,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,

  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,

  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),

  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),

  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),

  auditLogDetail: {
    status: RequestStatus.Idle,
  },
};

const getAuditLogListRequest: CaseReducer<
  AuditLogListState,
  PayloadAction<any>
> = (state: AuditLogListState) => {
  state.auditLogList.status = RequestStatus.Loading;
};

const getAuditLogListSuccess: CaseReducer<
  AuditLogListState,
  PayloadAction<GetAuditLogListResponseDTO>
> = (
  state: AuditLogListState,
  { payload }: PayloadAction<GetAuditLogListResponseDTO>,
) => {
  state.auditLogList.status = RequestStatus.Success;
  state.auditLogList.data = payload;
};

const getAuditLogListFailed: CaseReducer<AuditLogListState> = (
  state: AuditLogListState,
) => {
  state.auditLogList.status = RequestStatus.Failed;
};

const getDetailAuditLogRequest: CaseReducer<
  AuditLogListState,
  PayloadAction<string>
> = (state: AuditLogListState) => {
  state.auditLogDetail.status = RequestStatus.Loading;
};

const getDetailAuditSuccess: CaseReducer<
  AuditLogListState,
  PayloadAction<IAuditLogDetail>
> = (state: AuditLogListState, { payload }: PayloadAction<IAuditLogDetail>) => {
  state.auditLogDetail.status = RequestStatus.Success;
  state.auditLogDetail.data = payload;
};

const getDetailAuditFailed: CaseReducer<AuditLogListState> = (
  state: AuditLogListState,
) => {
  state.auditLogDetail.status = RequestStatus.Failed;
};

const appendAuditLogList: CaseReducer<AuditLogListState, PayloadAction<any>> = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  if (state.auditLogList?.data?.listAuditLog) {
    state.auditLogList.status = RequestStatus.Success;
    state.auditLogList.data.listAuditLog = [...payload];
  }
};

const setCanLoadMore = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMore = payload;
};

const resetAuditLogList: CaseReducer<AuditLogListState> = (
  state: AuditLogListState,
) => {
  state.auditLogList = {
    status: RequestStatus.Idle,
  };
};

const resetAuditLogListParams: CaseReducer<AuditLogListState> = (
  state: AuditLogListState,
) => {
  state.params = getDefaultAuditLogListRequestParams();
};

const setAuditLogListFilterParams = (
  state: AuditLogListState,
  { payload }: PayloadAction<GetAuditLogListRequestParamDTO>,
) => {
  state.params = payload;
};

// TODO: company
const fetchCompanyRequest: CaseReducer<
  AuditLogListState,
  PayloadAction<any>
> = (state: AuditLogListState) => {
  state.company.status = RequestStatus.Loading;
};

const setCompany = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  state.company.status = RequestStatus.Success;
  state.company.data = payload;
};

const appendCompany = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  state.company.status = RequestStatus.Success;
  state.company?.data?.customers.push(...payload);
};

const setCanLoadMoreCompany = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMoreCompany = payload;
};

const resetCompany = (state: AuditLogListState) => {
  state.company = {
    status: RequestStatus.Idle,
  };
  state.canLoadMoreCompany = true;
};

const fetchCompanySuccess: CaseReducer<
  AuditLogListState,
  PayloadAction<GetCompanyListDTO>
> = (
  state: AuditLogListState,
  { payload }: PayloadAction<GetCompanyListDTO>,
) => {
  state.company.status = RequestStatus.Success;
  state.company.data = payload;
};

const fetchCompanyFailed: CaseReducer<AuditLogListState> = (
  state: AuditLogListState,
) => {
  state.company.status = RequestStatus.Failed;
};

const setCompanyListParams = (
  state: AuditLogListState,
  { payload }: PayloadAction<GetCompanyListRequestParamsDTO>,
) => {
  state.paramsCompany = payload;
};

// TODO: users by compony

const fetchUsersRequest: CaseReducer<
  AuditLogListState,
  PayloadAction<GroupByUserRequestParamsDTO>
> = (state: AuditLogListState) => {
  state.usersByCompany.status = RequestStatus.Loading;
};

const setUsers = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  state.usersByCompany.status = RequestStatus.Success;
  state.usersByCompany.data = payload;
};

const appendUsers = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  state.usersByCompany.status = RequestStatus.Success;
  state.usersByCompany?.data?.customers.push(...payload);
};

const setCanLoadMoreUsers = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  state.canLoadMoreUsers = payload;
};

const resetUsers = (state: AuditLogListState) => {
  state.usersByCompany = {
    status: RequestStatus.Idle,
  };
  state.paramsUsersByCompany = getDefaultUsersParams();
  state.canLoadMoreUsers = true;
};

const fetchUsersSuccess: CaseReducer<AuditLogListState, PayloadAction<any>> = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  state.usersByCompany.status = RequestStatus.Success;
  state.usersByCompany.data = payload;
};

const fetchUsersFailed: CaseReducer<AuditLogListState> = (
  state: AuditLogListState,
) => {
  state.usersByCompany.status = RequestStatus.Failed;
};

const setUsersListParams = (
  state: AuditLogListState,
  { payload }: PayloadAction<GroupByUserRequestParamsDTO>,
) => {
  state.paramsUsersByCompany = payload;
};

// TODO: Category
const fetchCategoryRequest: CaseReducer<
  AuditLogListState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: AuditLogListState) => {
  state.category.status = RequestStatus.Loading;
};

const fetchCategorySuccess: CaseReducer<
  AuditLogListState,
  PayloadAction<GetCategoryDTO>
> = (state: AuditLogListState, { payload }: PayloadAction<GetCategoryDTO>) => {
  state.category.status = RequestStatus.Success;
  state.category.data = payload;
};

const fetchCategoryFailed: CaseReducer<AuditLogListState> = (
  state: AuditLogListState,
) => {
  state.category.status = RequestStatus.Failed;
};

const resetCategory = (state: AuditLogListState) => {
  state.category = {
    status: RequestStatus.Idle,
  };
};

const setCategoryListParams = (
  state: AuditLogListState,
  { payload }: PayloadAction<CategoryGroupByRequestParamsDTO>,
) => {
  state.paramsCategory = payload;
};

// TODO: Type
const fetchTypeRequest: CaseReducer<
  AuditLogListState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: AuditLogListState) => {
  state.type.status = RequestStatus.Loading;
};

const fetchTypeSuccess: CaseReducer<AuditLogListState, PayloadAction<any>> = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  state.type.status = RequestStatus.Success;
  state.type.data = payload;
};

const fetchTypeFailed: CaseReducer<AuditLogListState> = (
  state: AuditLogListState,
) => {
  state.type.status = RequestStatus.Failed;
};

// TODO: Action
const fetchActionRequest: CaseReducer<
  AuditLogListState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: AuditLogListState) => {
  state.action.status = RequestStatus.Loading;
};

const fetchActionSuccess: CaseReducer<AuditLogListState, PayloadAction<any>> = (
  state: AuditLogListState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};

const fetchActionFailed: CaseReducer<AuditLogListState> = (
  state: AuditLogListState,
) => {
  state.action.status = RequestStatus.Failed;
};

const auditLogListSlice = createSlice({
  name: 'auditLogList',
  initialState,
  reducers: {
    getAuditLogListRequest,
    getAuditLogListSuccess,
    getAuditLogListFailed,

    getDetailAuditLogRequest,
    getDetailAuditSuccess,
    getDetailAuditFailed,

    appendAuditLogList,

    setCanLoadMore,

    resetAuditLogList,

    setAuditLogListParams<K extends keyof GetAuditLogListRequestParamDTO>(
      state: AuditLogListState,
      action: PayloadAction<{
        key: K;
        value: GetAuditLogListRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
    },
    setAuditLogListFilterParams,
    resetAuditLogListParams,

    // company
    fetchCompanyRequest,
    setCompany,
    appendCompany,
    setCanLoadMoreCompany,
    resetCompany,
    fetchCompanySuccess,
    fetchCompanyFailed,
    setCompanyListParams,

    // username
    fetchUsersRequest,
    setUsers,
    appendUsers,
    setCanLoadMoreUsers,
    resetUsers,
    fetchUsersSuccess,
    fetchUsersFailed,
    setUsersListParams,

    // Category
    fetchCategoryRequest,
    fetchCategorySuccess,
    fetchCategoryFailed,
    resetCategory,
    setCategoryListParams,

    // Type
    fetchTypeRequest,
    fetchTypeSuccess,
    fetchTypeFailed,

    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
  },
});

// Actions
export const auditLogListActions = auditLogListSlice.actions;

// Reducer
export const auditLogListReducer = auditLogListSlice.reducer;

// Selectors
export const selectAuditLogList = (state: AppState) =>
  state.auditLogList?.auditLogList;

export const selectParamAuditLogList = (state: AppState) =>
  state.auditLogList?.params;

export const selectCanLoadMoreAuditLogList = (state: AppState) =>
  state.auditLogList?.canLoadMore;

export const selectAuditLogDetail = (state: AppState) =>
  state.auditLogList?.auditLogDetail;
