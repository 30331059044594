import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortAccountLimitTem, GroupByField, CustomerOnScreen } from 'enum/AccountLimitTem.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetAccountLimitTemRequestParamDTO,
  GetAccountLimitTemResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IAccountLimitTemDetail,
} from 'interfaces/AccountLimitTem';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface AccountLimitTemState {
  params: GetAccountLimitTemRequestParamDTO;
  AccountLimitTem: ResponseData<GetAccountLimitTemResponseDTO>;
  AccountLimitTemPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  AccountLimitTemDetail: ResponseData<IAccountLimitTemDetail>;
}
export function getDefaultAccountLimitTemRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortAccountLimitTem.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: AccountLimitTemState = {
  params: getDefaultAccountLimitTemRequestParams(),
  AccountLimitTem: {
    status: RequestStatus.Idle,
  },
  AccountLimitTemPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.ACCOUNT_LIMIT_TEMPLATE,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  AccountLimitTemDetail: {
    status: RequestStatus.Idle,
  },
};
const getAccountLimitTemRequest: CaseReducer<AccountLimitTemState, PayloadAction<any>> = (
  state: AccountLimitTemState,
) => {
  state.AccountLimitTem.status = RequestStatus.Loading;
};
const getAccountLimitTemSuccess: CaseReducer<
  AccountLimitTemState,
  PayloadAction<GetAccountLimitTemResponseDTO>
> = (state: AccountLimitTemState, { payload }: PayloadAction<GetAccountLimitTemResponseDTO>) => {
  state.AccountLimitTem.status = RequestStatus.Success;
  state.AccountLimitTem.data = payload;
};
const getAccountLimitTemFailed: CaseReducer<AccountLimitTemState> = (state: AccountLimitTemState) => {
  state.AccountLimitTem.status = RequestStatus.Failed;
};
const getDetailAccountLimitTemRequest: CaseReducer<AccountLimitTemState, PayloadAction<string>> = (
  state: AccountLimitTemState,
) => {
  state.AccountLimitTemDetail.status = RequestStatus.Loading;
};
const getDetailAccountLimitTemSuccess: CaseReducer<
  AccountLimitTemState,
  PayloadAction<IAccountLimitTemDetail>
> = (state: AccountLimitTemState, { payload }: PayloadAction<IAccountLimitTemDetail>) => {
  state.AccountLimitTemDetail.status = RequestStatus.Success;
  state.AccountLimitTemDetail.data = payload;
};
const getDetailAccountLimitTemFailed: CaseReducer<AccountLimitTemState> = (state: AccountLimitTemState) => {
  state.AccountLimitTemDetail.status = RequestStatus.Failed;
};
const createAccountLimitTemRequest: CaseReducer<AccountLimitTemState, PayloadAction<IAccountLimitTemDetail>> = (
  state: AccountLimitTemState,
) => {
  state.AccountLimitTemDetail.status = RequestStatus.Loading;
};
const createAccountLimitTemSuccess: CaseReducer<AccountLimitTemState, PayloadAction<IAccountLimitTemDetail>> = (
  state: AccountLimitTemState,
  { payload }: PayloadAction<IAccountLimitTemDetail>,
) => {
  state.AccountLimitTemDetail.status = RequestStatus.Success;
  state.AccountLimitTemDetail.data = payload;
};
const createAccountLimitTemFailed: CaseReducer<AccountLimitTemState> = (state: AccountLimitTemState) => {
  state.AccountLimitTemDetail.status = RequestStatus.Failed;
};
const updateAccountLimitTemRequest: CaseReducer<AccountLimitTemState, PayloadAction<IAccountLimitTemDetail>> = (
  state: AccountLimitTemState,
) => {
  state.AccountLimitTemDetail.status = RequestStatus.Loading;
};
const updateAccountLimitTemSuccess: CaseReducer<AccountLimitTemState, PayloadAction<IAccountLimitTemDetail>> = (
  state: AccountLimitTemState,
  { payload }: PayloadAction<IAccountLimitTemDetail>,
) => {
  state.AccountLimitTemDetail.status = RequestStatus.Success;
  state.AccountLimitTemDetail.data = payload;
};
const updateAccountLimitTemFailed: CaseReducer<AccountLimitTemState> = (state: AccountLimitTemState) => {
  state.AccountLimitTemDetail.status = RequestStatus.Failed;
};
const appendAccountLimitTem: CaseReducer<AccountLimitTemState, PayloadAction<any>> = (
  state: AccountLimitTemState,
  { payload }: PayloadAction<any>,
) => {
  if (state.AccountLimitTem?.data?.listAccountLimitTem) {
    state.AccountLimitTem.status = RequestStatus.Success;
    state.AccountLimitTem.data.listAccountLimitTem = [...payload];
  }
};
const setCanLoadMore = (state: AccountLimitTemState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetAccountLimitTem: CaseReducer<AccountLimitTemState> = (state: AccountLimitTemState) => {
  state.AccountLimitTem = {
    status: RequestStatus.Idle,
  };
};
const resetAccountLimitTemParams: CaseReducer<AccountLimitTemState> = (state: AccountLimitTemState) => {
  state.params = getDefaultAccountLimitTemRequestParams();
};
const setAccountLimitTemFilterParams = (
  state: AccountLimitTemState,
  { payload }: PayloadAction<GetAccountLimitTemRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  AccountLimitTemState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: AccountLimitTemState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<AccountLimitTemState, PayloadAction<any>> = (
  state: AccountLimitTemState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<AccountLimitTemState> = (state: AccountLimitTemState) => {
  state.action.status = RequestStatus.Failed;
};
const getAccountLimitTemPaginationConfigRequest: CaseReducer<
  AccountLimitTemState,
  PayloadAction<any>
> = (state: AccountLimitTemState) => {
  state.AccountLimitTemPaginationConfig.status = RequestStatus.Loading;
};
const getAccountLimitTemPaginationConfigSuccess: CaseReducer<
  AccountLimitTemState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: AccountLimitTemState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.AccountLimitTemPaginationConfig.status = RequestStatus.Success;
  state.AccountLimitTemPaginationConfig.data = payload;
};
const getAccountLimitTemPaginationConfigFailed: CaseReducer<AccountLimitTemState> = (
  state: AccountLimitTemState,
) => {
  state.AccountLimitTemPaginationConfig.status = RequestStatus.Failed;
};
const AccountLimitTemSlice = createSlice({
  name: 'AccountLimitTem',
  initialState,
  reducers: {
    getAccountLimitTemRequest,
    getAccountLimitTemSuccess,
    getAccountLimitTemFailed,
    getDetailAccountLimitTemRequest,
    getDetailAccountLimitTemSuccess,
    getDetailAccountLimitTemFailed,
    createAccountLimitTemRequest,
    createAccountLimitTemSuccess,
    createAccountLimitTemFailed,
    updateAccountLimitTemRequest,
    updateAccountLimitTemSuccess,
    updateAccountLimitTemFailed,
    appendAccountLimitTem,
    setCanLoadMore,
    resetAccountLimitTem,
    setAccountLimitTemParams<K extends keyof GetAccountLimitTemRequestParamDTO>(
      state: AccountLimitTemState,
      action: PayloadAction<{
        key: K;
        value: GetAccountLimitTemRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setAccountLimitTemFilterParams,
    resetAccountLimitTemParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getAccountLimitTemPaginationConfigRequest,
    getAccountLimitTemPaginationConfigSuccess,
    getAccountLimitTemPaginationConfigFailed,
  },
});
// Actions
export const AccountLimitTemActions = AccountLimitTemSlice.actions;
// Reducer
export const AccountLimitTemReducer = AccountLimitTemSlice.reducer;
// Selectors
export const selectAccountLimitTem = (state: AppState) => state.AccountLimitTem?.AccountLimitTem;
export const selectParamAccountLimitTem = (state: AppState) => state.AccountLimitTem?.params;
export const selectCanLoadMoreAccountLimitTem = (state: AppState) =>
  state.AccountLimitTem?.canLoadMore;
export const selectAccountLimitTemDetail = (state: AppState) => state.AccountLimitTem?.AccountLimitTemDetail;
export const selectAccountLimitTemPagination = (state: AppState) =>
  state.AccountLimitTem?.AccountLimitTemPaginationConfig;
