import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortAccountLimitCfg, GroupByField, CustomerOnScreen } from 'enum/AccountLimitCfg.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetAccountLimitCfgRequestParamDTO,
  GetAccountLimitCfgResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IAccountLimitCfgDetail,
} from 'interfaces/AccountLimitCfg';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface AccountLimitCfgState {
  params: GetAccountLimitCfgRequestParamDTO;
  AccountLimitCfg: ResponseData<GetAccountLimitCfgResponseDTO>;
  AccountLimitCfgPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  AccountLimitCfgDetail: ResponseData<IAccountLimitCfgDetail>;
}
export function getDefaultAccountLimitCfgRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortAccountLimitCfg.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: AccountLimitCfgState = {
  params: getDefaultAccountLimitCfgRequestParams(),
  AccountLimitCfg: {
    status: RequestStatus.Idle,
  },
  AccountLimitCfgPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.ACCOUNT_LIMIT_CFG,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  AccountLimitCfgDetail: {
    status: RequestStatus.Idle,
  },
};
const getAccountLimitCfgRequest: CaseReducer<AccountLimitCfgState, PayloadAction<any>> = (
  state: AccountLimitCfgState,
) => {
  state.AccountLimitCfg.status = RequestStatus.Loading;
};
const getAccountLimitCfgSuccess: CaseReducer<
  AccountLimitCfgState,
  PayloadAction<GetAccountLimitCfgResponseDTO>
> = (state: AccountLimitCfgState, { payload }: PayloadAction<GetAccountLimitCfgResponseDTO>) => {
  state.AccountLimitCfg.status = RequestStatus.Success;
  state.AccountLimitCfg.data = payload;
};
const getAccountLimitCfgFailed: CaseReducer<AccountLimitCfgState> = (state: AccountLimitCfgState) => {
  state.AccountLimitCfg.status = RequestStatus.Failed;
};
const getDetailAccountLimitCfgRequest: CaseReducer<AccountLimitCfgState, PayloadAction<string>> = (
  state: AccountLimitCfgState,
) => {
  state.AccountLimitCfgDetail.status = RequestStatus.Loading;
};
const getDetailAccountLimitCfgSuccess: CaseReducer<
  AccountLimitCfgState,
  PayloadAction<IAccountLimitCfgDetail>
> = (state: AccountLimitCfgState, { payload }: PayloadAction<IAccountLimitCfgDetail>) => {
  state.AccountLimitCfgDetail.status = RequestStatus.Success;
  state.AccountLimitCfgDetail.data = payload;
};
const getDetailAccountLimitCfgFailed: CaseReducer<AccountLimitCfgState> = (state: AccountLimitCfgState) => {
  state.AccountLimitCfgDetail.status = RequestStatus.Failed;
};
const createAccountLimitCfgRequest: CaseReducer<AccountLimitCfgState, PayloadAction<IAccountLimitCfgDetail>> = (
  state: AccountLimitCfgState,
) => {
  state.AccountLimitCfgDetail.status = RequestStatus.Loading;
};
const createAccountLimitCfgSuccess: CaseReducer<AccountLimitCfgState, PayloadAction<IAccountLimitCfgDetail>> = (
  state: AccountLimitCfgState,
  { payload }: PayloadAction<IAccountLimitCfgDetail>,
) => {
  state.AccountLimitCfgDetail.status = RequestStatus.Success;
  state.AccountLimitCfgDetail.data = payload;
};
const createAccountLimitCfgFailed: CaseReducer<AccountLimitCfgState> = (state: AccountLimitCfgState) => {
  state.AccountLimitCfgDetail.status = RequestStatus.Failed;
};
const updateAccountLimitCfgRequest: CaseReducer<AccountLimitCfgState, PayloadAction<IAccountLimitCfgDetail>> = (
  state: AccountLimitCfgState,
) => {
  state.AccountLimitCfgDetail.status = RequestStatus.Loading;
};
const updateAccountLimitCfgSuccess: CaseReducer<AccountLimitCfgState, PayloadAction<IAccountLimitCfgDetail>> = (
  state: AccountLimitCfgState,
  { payload }: PayloadAction<IAccountLimitCfgDetail>,
) => {
  state.AccountLimitCfgDetail.status = RequestStatus.Success;
  state.AccountLimitCfgDetail.data = payload;
};
const updateAccountLimitCfgFailed: CaseReducer<AccountLimitCfgState> = (state: AccountLimitCfgState) => {
  state.AccountLimitCfgDetail.status = RequestStatus.Failed;
};
const appendAccountLimitCfg: CaseReducer<AccountLimitCfgState, PayloadAction<any>> = (
  state: AccountLimitCfgState,
  { payload }: PayloadAction<any>,
) => {
  if (state.AccountLimitCfg?.data?.listAccountLimitCfg) {
    state.AccountLimitCfg.status = RequestStatus.Success;
    state.AccountLimitCfg.data.listAccountLimitCfg = [...payload];
  }
};
const setCanLoadMore = (state: AccountLimitCfgState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetAccountLimitCfg: CaseReducer<AccountLimitCfgState> = (state: AccountLimitCfgState) => {
  state.AccountLimitCfg = {
    status: RequestStatus.Idle,
  };
};
const resetAccountLimitCfgParams: CaseReducer<AccountLimitCfgState> = (state: AccountLimitCfgState) => {
  state.params = getDefaultAccountLimitCfgRequestParams();
};
const setAccountLimitCfgFilterParams = (
  state: AccountLimitCfgState,
  { payload }: PayloadAction<GetAccountLimitCfgRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  AccountLimitCfgState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: AccountLimitCfgState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<AccountLimitCfgState, PayloadAction<any>> = (
  state: AccountLimitCfgState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<AccountLimitCfgState> = (state: AccountLimitCfgState) => {
  state.action.status = RequestStatus.Failed;
};
const getAccountLimitCfgPaginationConfigRequest: CaseReducer<
  AccountLimitCfgState,
  PayloadAction<any>
> = (state: AccountLimitCfgState) => {
  state.AccountLimitCfgPaginationConfig.status = RequestStatus.Loading;
};
const getAccountLimitCfgPaginationConfigSuccess: CaseReducer<
  AccountLimitCfgState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: AccountLimitCfgState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.AccountLimitCfgPaginationConfig.status = RequestStatus.Success;
  state.AccountLimitCfgPaginationConfig.data = payload;
};
const getAccountLimitCfgPaginationConfigFailed: CaseReducer<AccountLimitCfgState> = (
  state: AccountLimitCfgState,
) => {
  state.AccountLimitCfgPaginationConfig.status = RequestStatus.Failed;
};
const AccountLimitCfgSlice = createSlice({
  name: 'AccountLimitCfg',
  initialState,
  reducers: {
    getAccountLimitCfgRequest,
    getAccountLimitCfgSuccess,
    getAccountLimitCfgFailed,
    getDetailAccountLimitCfgRequest,
    getDetailAccountLimitCfgSuccess,
    getDetailAccountLimitCfgFailed,
    createAccountLimitCfgRequest,
    createAccountLimitCfgSuccess,
    createAccountLimitCfgFailed,
    updateAccountLimitCfgRequest,
    updateAccountLimitCfgSuccess,
    updateAccountLimitCfgFailed,
    appendAccountLimitCfg,
    setCanLoadMore,
    resetAccountLimitCfg,
    setAccountLimitCfgParams<K extends keyof GetAccountLimitCfgRequestParamDTO>(
      state: AccountLimitCfgState,
      action: PayloadAction<{
        key: K;
        value: GetAccountLimitCfgRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setAccountLimitCfgFilterParams,
    resetAccountLimitCfgParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getAccountLimitCfgPaginationConfigRequest,
    getAccountLimitCfgPaginationConfigSuccess,
    getAccountLimitCfgPaginationConfigFailed,
  },
});
// Actions
export const AccountLimitCfgActions = AccountLimitCfgSlice.actions;
// Reducer
export const AccountLimitCfgReducer = AccountLimitCfgSlice.reducer;
// Selectors
export const selectAccountLimitCfg = (state: AppState) => state.AccountLimitCfg?.AccountLimitCfg;
export const selectParamAccountLimitCfg = (state: AppState) => state.AccountLimitCfg?.params;
export const selectCanLoadMoreAccountLimitCfg = (state: AppState) =>
  state.AccountLimitCfg?.canLoadMore;
export const selectAccountLimitCfgDetail = (state: AppState) => state.AccountLimitCfg?.AccountLimitCfgDetail;
export const selectAccountLimitCfgPagination = (state: AppState) =>
  state.AccountLimitCfg?.AccountLimitCfgPaginationConfig;
