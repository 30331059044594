import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Params } from 'interfaces/request';
import { PER_PAGE } from 'constants/index';
import { Sort, SortDirection } from 'enum/common';
import { SortQuorumRecord, GroupByField, CustomerOnScreen } from 'enum/QuorumRecord.enum';
import { RequestStatus } from 'interfaces/request';
import { ResponseData } from 'interfaces/response';
import {
  CategoryGroupByRequestParamsDTO,
  GetQuorumRecordRequestParamDTO,
  GetQuorumRecordResponseDTO,
  GetCategoryDTO,
  GetCompanyListDTO,
  GetCompanyListRequestParamsDTO,
  GroupByUserRequestParamsDTO,
  IQuorumRecordDetail,
} from 'interfaces/QuorumRecord';
import { AppState } from '../..';
import { IRakCRUDBaseControllerConfig } from 'interfaces/dynamicFilter';
interface QuorumRecordState {
  params: GetQuorumRecordRequestParamDTO;
  QuorumRecord: ResponseData<GetQuorumRecordResponseDTO>;
  QuorumRecordPaginationConfig: ResponseData<IRakCRUDBaseControllerConfig>;
  canLoadMore: boolean;
  paramsCompany: GetCompanyListRequestParamsDTO;
  company: ResponseData<GetCompanyListDTO>;
  canLoadMoreCompany: boolean;
  usersByCompany: ResponseData<any>;
  paramsUsersByCompany: GroupByUserRequestParamsDTO;
  canLoadMoreUsers: boolean;
  category: ResponseData<GetCategoryDTO>;
  paramsCategory: CategoryGroupByRequestParamsDTO;
  type: ResponseData<any>;
  paramsType: CategoryGroupByRequestParamsDTO;
  action: ResponseData<any>;
  paramsAction: CategoryGroupByRequestParamsDTO;
  QuorumRecordDetail: ResponseData<IQuorumRecordDetail>;
}
export function getDefaultQuorumRecordRequestParams() {
  return {
    [Params.Offset]: 0,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: SortQuorumRecord.createdAt,
    [Params.Sort]: SortDirection.DESC,
    [Params.isAllRequest]: false,
  };
}
export function getDefaultCompanyRequestParams() {}
export function getDefaultCategoryGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultTypeGroupByRequestParams() {
  return {
    groupBy: GroupByField.CATEGORY,
  };
}
export function getDefaultActionGroupByRequestParams() {
  return {
    groupBy: GroupByField.TYPE,
  };
}
export function getDefaultUsersParams() {
  return {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    customerIds: '',
  };
}
const initialState: QuorumRecordState = {
  params: getDefaultQuorumRecordRequestParams(),
  QuorumRecord: {
    status: RequestStatus.Idle,
  },
  QuorumRecordPaginationConfig: {
    status: RequestStatus.Idle,
  },
  canLoadMore: true,
  paramsCompany: {
    [Params.Page]: 1,
    [Params.Limit]: PER_PAGE,
    [Params.KeyWord]: '',
    [Params.Search]: '',
    [Params.SortBy]: Sort.CUSTOMER_NAME,
    [Params.Sort]: SortDirection.ASC,
    [Params.SearchOnScreen]: CustomerOnScreen.QUORUM_RECORD,
  },
  company: {
    status: RequestStatus.Idle,
  },
  canLoadMoreCompany: true,
  usersByCompany: {
    status: RequestStatus.Idle,
  },
  paramsUsersByCompany: getDefaultUsersParams(),
  canLoadMoreUsers: true,
  category: {
    status: RequestStatus.Idle,
  },
  paramsCategory: getDefaultCategoryGroupByRequestParams(),
  type: {
    status: RequestStatus.Idle,
  },
  paramsType: getDefaultTypeGroupByRequestParams(),
  action: {
    status: RequestStatus.Idle,
  },
  paramsAction: getDefaultActionGroupByRequestParams(),
  QuorumRecordDetail: {
    status: RequestStatus.Idle,
  },
};
const getQuorumRecordRequest: CaseReducer<QuorumRecordState, PayloadAction<any>> = (
  state: QuorumRecordState,
) => {
  state.QuorumRecord.status = RequestStatus.Loading;
};
const getQuorumRecordSuccess: CaseReducer<
  QuorumRecordState,
  PayloadAction<GetQuorumRecordResponseDTO>
> = (state: QuorumRecordState, { payload }: PayloadAction<GetQuorumRecordResponseDTO>) => {
  state.QuorumRecord.status = RequestStatus.Success;
  state.QuorumRecord.data = payload;
};
const getQuorumRecordFailed: CaseReducer<QuorumRecordState> = (state: QuorumRecordState) => {
  state.QuorumRecord.status = RequestStatus.Failed;
};
const getDetailQuorumRecordRequest: CaseReducer<QuorumRecordState, PayloadAction<string>> = (
  state: QuorumRecordState,
) => {
  state.QuorumRecordDetail.status = RequestStatus.Loading;
};
const getDetailQuorumRecordSuccess: CaseReducer<
  QuorumRecordState,
  PayloadAction<IQuorumRecordDetail>
> = (state: QuorumRecordState, { payload }: PayloadAction<IQuorumRecordDetail>) => {
  state.QuorumRecordDetail.status = RequestStatus.Success;
  state.QuorumRecordDetail.data = payload;
};
const getDetailQuorumRecordFailed: CaseReducer<QuorumRecordState> = (state: QuorumRecordState) => {
  state.QuorumRecordDetail.status = RequestStatus.Failed;
};
const createQuorumRecordRequest: CaseReducer<QuorumRecordState, PayloadAction<IQuorumRecordDetail>> = (
  state: QuorumRecordState,
) => {
  state.QuorumRecordDetail.status = RequestStatus.Loading;
};
const createQuorumRecordSuccess: CaseReducer<QuorumRecordState, PayloadAction<IQuorumRecordDetail>> = (
  state: QuorumRecordState,
  { payload }: PayloadAction<IQuorumRecordDetail>,
) => {
  state.QuorumRecordDetail.status = RequestStatus.Success;
  state.QuorumRecordDetail.data = payload;
};
const createQuorumRecordFailed: CaseReducer<QuorumRecordState> = (state: QuorumRecordState) => {
  state.QuorumRecordDetail.status = RequestStatus.Failed;
};
const updateQuorumRecordRequest: CaseReducer<QuorumRecordState, PayloadAction<IQuorumRecordDetail>> = (
  state: QuorumRecordState,
) => {
  state.QuorumRecordDetail.status = RequestStatus.Loading;
};
const updateQuorumRecordSuccess: CaseReducer<QuorumRecordState, PayloadAction<IQuorumRecordDetail>> = (
  state: QuorumRecordState,
  { payload }: PayloadAction<IQuorumRecordDetail>,
) => {
  state.QuorumRecordDetail.status = RequestStatus.Success;
  state.QuorumRecordDetail.data = payload;
};
const updateQuorumRecordFailed: CaseReducer<QuorumRecordState> = (state: QuorumRecordState) => {
  state.QuorumRecordDetail.status = RequestStatus.Failed;
};
const appendQuorumRecord: CaseReducer<QuorumRecordState, PayloadAction<any>> = (
  state: QuorumRecordState,
  { payload }: PayloadAction<any>,
) => {
  if (state.QuorumRecord?.data?.listQuorumRecord) {
    state.QuorumRecord.status = RequestStatus.Success;
    state.QuorumRecord.data.listQuorumRecord = [...payload];
  }
};
const setCanLoadMore = (state: QuorumRecordState, { payload }: PayloadAction<any>) => {
  state.canLoadMore = payload;
};
const resetQuorumRecord: CaseReducer<QuorumRecordState> = (state: QuorumRecordState) => {
  state.QuorumRecord = {
    status: RequestStatus.Idle,
  };
};
const resetQuorumRecordParams: CaseReducer<QuorumRecordState> = (state: QuorumRecordState) => {
  state.params = getDefaultQuorumRecordRequestParams();
};
const setQuorumRecordFilterParams = (
  state: QuorumRecordState,
  { payload }: PayloadAction<GetQuorumRecordRequestParamDTO>,
) => {
  state.params = payload;
};
// TODO: Action
const fetchActionRequest: CaseReducer<
  QuorumRecordState,
  PayloadAction<CategoryGroupByRequestParamsDTO>
> = (state: QuorumRecordState) => {
  state.action.status = RequestStatus.Loading;
};
const fetchActionSuccess: CaseReducer<QuorumRecordState, PayloadAction<any>> = (
  state: QuorumRecordState,
  { payload }: PayloadAction<any>,
) => {
  state.action.status = RequestStatus.Success;
  state.action.data = payload;
};
const fetchActionFailed: CaseReducer<QuorumRecordState> = (state: QuorumRecordState) => {
  state.action.status = RequestStatus.Failed;
};
const getQuorumRecordPaginationConfigRequest: CaseReducer<
  QuorumRecordState,
  PayloadAction<any>
> = (state: QuorumRecordState) => {
  state.QuorumRecordPaginationConfig.status = RequestStatus.Loading;
};
const getQuorumRecordPaginationConfigSuccess: CaseReducer<
  QuorumRecordState,
  PayloadAction<IRakCRUDBaseControllerConfig>
> = (
  state: QuorumRecordState,
  { payload }: PayloadAction<IRakCRUDBaseControllerConfig>,
) => {
  state.QuorumRecordPaginationConfig.status = RequestStatus.Success;
  state.QuorumRecordPaginationConfig.data = payload;
};
const getQuorumRecordPaginationConfigFailed: CaseReducer<QuorumRecordState> = (
  state: QuorumRecordState,
) => {
  state.QuorumRecordPaginationConfig.status = RequestStatus.Failed;
};
const QuorumRecordSlice = createSlice({
  name: 'QuorumRecord',
  initialState,
  reducers: {
    getQuorumRecordRequest,
    getQuorumRecordSuccess,
    getQuorumRecordFailed,
    getDetailQuorumRecordRequest,
    getDetailQuorumRecordSuccess,
    getDetailQuorumRecordFailed,
    createQuorumRecordRequest,
    createQuorumRecordSuccess,
    createQuorumRecordFailed,
    updateQuorumRecordRequest,
    updateQuorumRecordSuccess,
    updateQuorumRecordFailed,
    appendQuorumRecord,
    setCanLoadMore,
    resetQuorumRecord,
    setQuorumRecordParams<K extends keyof GetQuorumRecordRequestParamDTO>(
      state: QuorumRecordState,
      action: PayloadAction<{
        key: K;
        value: GetQuorumRecordRequestParamDTO[K];
      }>,
    ) {
      state.params[action.payload.key] = action.payload.value;
      if (action.payload.key === Params.KeyWord) {
        state.params[Params.KeyWord.toString()] = action.payload.value;
      }
    },
    setQuorumRecordFilterParams,
    resetQuorumRecordParams,
    // Action
    fetchActionRequest,
    fetchActionSuccess,
    fetchActionFailed,
    // pagination
    getQuorumRecordPaginationConfigRequest,
    getQuorumRecordPaginationConfigSuccess,
    getQuorumRecordPaginationConfigFailed,
  },
});
// Actions
export const QuorumRecordActions = QuorumRecordSlice.actions;
// Reducer
export const QuorumRecordReducer = QuorumRecordSlice.reducer;
// Selectors
export const selectQuorumRecord = (state: AppState) => state.QuorumRecord?.QuorumRecord;
export const selectParamQuorumRecord = (state: AppState) => state.QuorumRecord?.params;
export const selectCanLoadMoreQuorumRecord = (state: AppState) =>
  state.QuorumRecord?.canLoadMore;
export const selectQuorumRecordDetail = (state: AppState) => state.QuorumRecord?.QuorumRecordDetail;
export const selectQuorumRecordPagination = (state: AppState) =>
  state.QuorumRecord?.QuorumRecordPaginationConfig;
