import { Tooltip } from 'antd';
import BasicIconSort from 'app/components/common/BasicIconSort';
import NoResultWhenSearch from 'app/components/common/NoResultWhenSearch';
import RakkarStatusButton from 'app/components/common/RakkarStatusButton';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { infoTxn } from 'app/components/PendingRequestDrawer/UnfreezeTransactionRequest/components/TransactionInformation/index.helper';
import RTable from 'app/components/Table';
import clsx from 'clsx';
import {
  commonAttributesColumnTable,
  FORMAT_DATE,
  PER_PAGE,
} from 'constants/index';
import { Sort, SortDirection, TransactionType } from 'enum/common';
import {
  SortTransactionListTable,
  TransactionStatusResponse,
} from 'enum/transaction';
import { Params, RequestStatus } from 'interfaces/request';
import {
  TransactionParamsRequest,
  TransactionRecord,
} from 'interfaces/transactions';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  selectCanLoadMoreTransactions,
  selectparamsTransactionList,
  selectTransactionList,
  transactionsActions,
} from 'store/slices/transactions';
import { formatAssetDetails, formatCurrencyTypeB } from 'utils/helpers';
import DetailTransaction from '../DetailTransaction';
import styles from './index.module.scss';
import { subStatuses } from '../DetailTransaction/TransactionInformation';
import { cfg_countryActions } from 'store/slices/cfg_country/cfg_country.slice';

type Props = {
  hasSearchOrFilter?: boolean;
  showEmptyScreen?: boolean;
  isHasFilter?: boolean;
};

const ListTransaction = ({ showEmptyScreen, isHasFilter }: Props) => {
  const dispatch = useDispatch();
  const transactionList = useAppSelector(selectTransactionList);
  const params = useAppSelector(selectparamsTransactionList);
  const canLoadMore = useAppSelector(selectCanLoadMoreTransactions);
  const [transactions, setTransactions] = useState<TransactionRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const refCanLoadMoreStore = useRef(canLoadMore);
  const [open, setOpen] = useState(false);
  const itemSelected = useRef<TransactionRecord | undefined>();
  const [firstRender, setFirstRender] = useState(true);
  const [selectedRowId, setSelectedRowId] = useState('');

  const heightHeader =
    document.querySelector('#table-transaction')?.getBoundingClientRect()?.y ||
    0;
  const height = window.innerHeight - heightHeader - 260; //count table height

  const setValue = (key: keyof TransactionParamsRequest) => {
    return (
      value: TransactionParamsRequest[keyof TransactionParamsRequest],
    ) => {
      dispatch(
        transactionsActions.setTransactionParam({
          key,
          value,
        }),
      );
    };
  };

  const setSort = setValue(Params.Sort);
  const setSortBy = setValue(Params.SortBy);
  const setOffset = setValue(Params.Offset);

  useEffect(() => {
    refCanLoadMoreStore.current = canLoadMore;
  }, [canLoadMore]);

  useEffect(
    () => {
      dispatch(cfg_countryActions.getCfg_countryRequest({ limit: 1000 }));
    },
    []
  )

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    return () => {
      if (!firstRender) {
        dispatch(transactionsActions.resetParamsTransaction());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  useEffect(() => {
    dispatch(transactionsActions.getTransactionListRequest(params));
  }, [params]);

  useEffect(() => {
    if (transactionList?.data?.transactions) {
      setTransactions(transactionList.data.transactions);
    }
    if (transactionList?.status === RequestStatus.Loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [transactionList]);

  useEffect(() => {
    let tableContent: any = document.querySelector('.ant-table-body');
    tableContent &&
      tableContent.addEventListener('scroll', event => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = (event.target.scrollTop + 2).toFixed();
        let offset = params?.offset || 0;
        if (maxScroll === 0 && offset > 0) {
          offset = 0;
        }
        if (
          refCanLoadMoreStore.current &&
          Number(currentScroll) > maxScroll &&
          transactions.length === offset + PER_PAGE
        ) {
          setOffset(offset + PER_PAGE);
        }
      });
  }, [params?.offset, canLoadMore, setOffset, transactions]);

  useEffect(() => {
    const length = transactions.length || 0;
    const count = height / 46; //46 height 1 record
    let offset = Number(params?.offset) + PER_PAGE || PER_PAGE;
    const total = transactionList?.data?.totalCount ?? 0;

    if (offset < count && offset < total && length === offset) {
      setOffset(offset);
    }
  }, [transactions, params?.offset]);

  const convertSortOrder = value => {
    if (value === SortDirection.ASC) {
      return 'ascend';
    }
    return 'descend';
  };

  const renderIcon = (
    sortOrder: string,
    sortColumn: any,
    title: string,
    dataIndex: string,
    align = 'left' as string,
  ) => {
    return (
      <>
        <div
          className={clsx(styles.title, {
            [styles.titleHeaderRight]: align === 'right',
          })}
          data-testid={`column-${dataIndex}`}
        >
          <span>{title}</span>
          {sortOrder && sortColumn?.dataIndex === dataIndex && (
            <BasicIconSort sortOrder={sortOrder} />
          )}
        </div>
      </>
    );
  };

  const columns: any = [
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Initiated date', 'createdAt');
      },
      defaultSortOrder: 'ascend',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ...(params?.sortBy === Sort.CREATED_DATE && {
        sortOrder: convertSortOrder(params.sort),
      }),
      render: (date: string) => {
        return (
          <div className={styles.date}>
            {moment(date).format(FORMAT_DATE.DATE_DMY_hM)}
          </div>
        );
      },
      width: 170,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'Transaction ID',
          'transactionId',
        );
      },
      dataIndex: 'transactionId',
      key: 'transactionId',
      render: (transactionId: string) => {
        return (
          <div className={`${styles.ellipsis} ${styles.id}`}>
            {transactionId}
          </div>
        );
      },
      width: 320,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(
          sortOrder,
          sortColumn,
          'Customer name',
          'customerName',
        );
      },
      dataIndex: 'customerName',
      key: 'customerName',
      render: (name: string) => {
        return <div className={styles.name}>{name}</div>;
      },
      width: 150,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Type', 'type');
      },
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => {
        return (
          <div className={styles.type}>
            <Tooltip title={infoTxn(type as TransactionType)?.title}>
              <SvgIcon
                name={infoTxn(type as TransactionType)?.icon}
                width={16}
                height={16}
              />
            </Tooltip>
          </div>
        );
      },
      width: 80,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Asset', 'symbol');
      },
      dataIndex: 'symbol',
      key: 'symbol',
      render: (_: any, item: TransactionRecord) => (
        <div className={styles.symbol}>
          <div className={styles.icon}>
            <img src={item.image} alt="" />
          </div>
          <div className={styles.symbolName}>{item.symbol}</div>
        </div>
      ),
      width: 100,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Amount', 'amount', 'right');
      },
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (amount: number, record) => {
        return (
          <div className={styles.amount}>
            <Tooltip placement="top" title={formatAssetDetails(record?.amount)}>
              <div className={styles.value}>
                {formatAssetDetails(record?.amount)}
              </div>
            </Tooltip>
            <div className={styles.usd}>
              ${formatCurrencyTypeB(record?.amountUSD)}
            </div>
          </div>
        );
      },
      width: 100,
    },
    {
      ...commonAttributesColumnTable,
      title: ({ sortOrder, sortColumn }: any) => {
        return renderIcon(sortOrder, sortColumn, 'Status', 'status');
      },
      dataIndex: 'repStatus',
      key: 'repStatus',
      render: (repStatus: string, record) => {
        let mappedRepStatus;
        mappedRepStatus = repStatus;
        return <RakkarStatusButton status={mappedRepStatus} />;
      },
      width: 140,
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSort(
      sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : undefined,
    );
    setSortBy(
      sorter.order ? SortTransactionListTable[sorter.field] : undefined,
    );
    setOffset(0);
  };

  const handleClickOnRow = (values: any) => {
    if (values) {
      setOpen(true);
      itemSelected.current = values;
      setSelectedRowId(values.id);
    }
  };

  if (showEmptyScreen)
    return (
      <NoResultWhenSearch
        title={isHasFilter ? 'No matching results' : 'No search results'}
        description={
          isHasFilter ? (
            'Your search returns no matches, please check and try again.'
          ) : (
            <div style={{ marginTop: 8 }}>
              Your search returns no matches,
              <br /> please check and try again.
            </div>
          )
        }
      />
    );

  return (
    <div className={styles.table} data-testid="table">
      All {transactionList?.data?.totalCount?.toLocaleString() || "loading..."} transactions
      <RTable
        currentTableData={transactions}
        columns={columns}
        loading={loading}
        height={height}
        selectedRowId={selectedRowId}
        onChange={handleTableChange}
        clickOnRow={handleClickOnRow}
      />
      {open && itemSelected.current && (
        <DetailTransaction
          open={open}
          setOpen={value => {
            setOpen(value);
            setSelectedRowId('');
          }}
          detail={itemSelected.current}
          txnId={itemSelected.current.id}
        />
      )}
    </div>
  );
};

export default ListTransaction;
