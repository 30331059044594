import { AuditLogStatus, TransactionStatus } from 'enum/common';
import { capitalize, lowerCase, snakeCase } from 'lodash';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  status: string;
  template?: any;
  notFound?: any;
};

const RakkarStatusButton: React.FC<Props> = ({
  status,
  template = TransactionStatus,
  notFound = '',
}) => {
  const repStatus = template[status as keyof typeof template];

  if (!repStatus) return notFound;

  return (
    <div
      className={`${styles.container} ${styles[snakeCase(status)]}`}
      data-testid="status"
    >
      {status === AuditLogStatus.SUCCESSFUL ? 'Success' : capitalize(repStatus)}
    </div>
  );
};

export default RakkarStatusButton;
